<template>
  <div>
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("Specialities") }}
      </p>
    </div>
    <div class="p-5">
      <div style="font-weight: bold">{{ $t("ChooseMainSpecialty") }}</div>

      <div class="flex flex-col m-auto" v-if="surgeryCategories.length > 0">
        <div class="flex overflow-x-scroll py-6 items hide-scroll-bar">
          <div
            class="inline-block"
            v-on:click="selectSurgCategory(null, null, null)"
          >
            <div
              style="background-color: #355a85"
              :class="[{ 'bg-primary': ActiveParentIndex == null }]"
              class="RoundedCurve w-48 pb-8 max-w-xs overflow-hidden"
            >
              <h4
                class="mx-auto text-center mt-8 w-36 justify-center text-white"
              >
                {{ $t("All") }}
              </h4>
            </div>
          </div>
          <div
            class="flex flex-nowrap lg:ml-40 md:ml-20 ml-10"
            v-for="(item, index) in surgeryCategories"
            :key="index"
          >
            <div
              class="inline-block px-1"
              v-on:click="selectSurgCategory(item, index, 1)"
            >
              <div
                :class="[{ 'bg-primary': ActiveParentIndex == index }]"
                style="background-color: #355a85"
                class="RoundedCurve w-48 pb-8 max-w-xs overflow-hidden"
              >
                <h4
                  class="
                    mx-auto
                    text-center
                    mt-8
                    w-36
                    justify-center
                    text-white
                  "
                >
                  {{ item.Specialty.Name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="font-weight: bold" class="">
        {{ $t("ChooseSubSpecialty") }}
      </div>

      <div class="flex flex-col m-auto" v-if="surgeriesByCat.length > 0">
        <div class="flex overflow-x-scroll py-4 hide-scroll-bar">
          <div
            class="flex flex-nowrap lg:ml-40 md:ml-2 ml-10"
            v-for="(item, index) in surgeriesByCat"
            :key="index"
          >
            <div
              class="inline-block px-1"
              v-on:click="selectSurgCategory(item, index, 2)"
            >
              <div
                style="background-color: #355a85"
                class="RoundedCurve w-64 h-16 max-w-xs overflow-hidden"
                :class="[{ 'bg-primary': ActiveChildIndex == index }]"
              >
                <h5
                  class="
                    mx-auto
                    text-center
                    mt-3
                    w-64
                    justify-center
                    text-white
                  "
                >
                  {{ item.Specialty.Name }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-weight: bold" class="mt-2">
        {{ $t("SurgeryTreatment") }}
      </div>
      <div class="vx-row" v-for="item in hospitalSurgery" :key="item.ID">
        <span class="vx-col lg:w-2/3 mt-5">{{ item.Surgery.Name }}</span>
        <vs-button
          @click="ViewSurgeryData(item)"
          class="vx-col lg:w-1/6 w-full h-12 mt-3 customizer-btn text-white"
        >
          {{ $t("GotoTreatment") }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";
export default {
  data() {
    return {
      EnglishLan: false,
      surgeryCategories: [],
      surgeriesByCat: [],
      hospitalSpecialty: [],
      ActiveParentIndex: null,
      ActiveChildIndex: null,
      SurgActiveIndex: null,
      hospitalSurgery: [],
    };
  },
  methods: {
    ViewSurgeryData(surgery) {
      this.$router.push("/TreatmentsProfile/" + surgery.ID + "/" + 1);
    },
    back() {
      this.$router.go(-1);
    },
    selectSurgCategory(data, index, flag) {
      if (flag == 1) {
          this.ActiveParentIndex = index;
           this.ActiveChildIndex=-1;
          this.surgeriesByCat = this.hospitalSpecialty.filter(
            (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
          );
        }
        else if(flag == 2)
        {
            this.ActiveChildIndex = index;
        }
        else
        {
          this.ActiveParentIndex = index;
        }

      debugger;
      if (data != null) {
        

        this.$store
          .dispatch("SurgeryList/SearchHospitalSurgerys", {
            HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
            SpecialtyID: data.Specialty.ID,
          })
          .then((res) => {
            debugger;
            this.hospitalSurgery = res.data.Data;
            debugger;
          });
      } else {
      

        this.surgeriesByCat = this.hospitalSpecialty.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
        this.$store
          .dispatch("SurgeryList/SearchHospitalSurgerys", {
            HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
          })
          .then((res) => {
            this.hospitalSurgery = res.data.Data;
          });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }
    this.$store
      .dispatch("SurgeryList/SearchHospitalSurgerys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      })
      .then((res) => {
        this.hospitalSurgery = res.data.Data;
      });

    this.$store
      .dispatch("HospitalList/SearchHospitalSpecialtys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      })
      .then((res) => {
        this.hospitalSpecialty = res.data.Data;
        this.surgeryCategories = res.data.Data.filter(
          (ob) => !ob.Specialty.ParentSpecialtyID
        );
        this.surgeriesByCat = res.data.Data.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
      });
  },
};
</script>

<style scoped>
.RoundedCurve {
  border-radius: 20px;
  background: #355a85;
}
</style>